import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import PhoneMobileImage from "../../images/phone-mobile.svg";
import WebImage from "../../images/web-icon.svg";
import ConsultingImage from "../../images/support-consulting.svg";
import IaLearning from "../../images/ia-machinelearning.svg";
import SystemImage from "../../images/system-integrations.svg";
import IOT from "../../images/IOT.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: PhoneMobileImage,
      title: "Desarrollo Movil",
      description: "Nuestro equipo tiene la experiencia para desarrollar aplicaciones móviles robustas, seguras y alineadas con los objetivos estratégicos de nuestros clientes."
    },
    { imageSrc: WebImage, title: "Desarrollo Web", description: "Desarrollamos aplicaciones web a la medida, cumpliendo con los requisitos y expectativas únicas de cada cliente." },
    { imageSrc: ConsultingImage, title: "Consultoría", description: "Ofrecemos asesoría integral para ayudar a las empresas a adaptarse a la era digital y obtener ventajas competitivas a través de la tecnología." },
    { imageSrc: SystemImage, title: "Integración de Sistemas", description: "La integración efectiva de sistemas mejora la comunicación, reduce errores y permite una toma de decisiones más rápida y fundamentada." },
    { imageSrc: IaLearning, title: "IA/Machine Learning" , description: "La IA y el machine learning pueden hacer que las aplicaciones sean más inteligentes, automatizadas y personalizadas para los usuarios."},
    { imageSrc: IOT, title: "Internet de las cosas (IoT)", description: "El IoT permite la recopilación y análisis de datos en tiempo real para la optimización de operaciones y automatización de procesos, resultando en ganancias significativas de eficiencia para las empresas." }
  ];

  return (
    <Container id="servicios" >
      <ThreeColumnContainer>
        <Heading>Nuestros <span tw="text-primary-500">Servicios</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
