import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Encuentra respuestas a las preguntas más comunes sobre nuestros servicios de desarrollo móvil y web en nuestra sección de Preguntas Frecuentes (FAQ). Hemos recopilado información valiosa para ayudarte a comprender mejor nuestros servicios, procesos y enfoque. Si tienes alguna pregunta adicional o necesitas más detalles, no dudes en contactarnos. Estamos aquí para ayudarte a tomar decisiones informadas y exitosas en tu proyecto.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "¿Cuál es la diferencia entre desarrollo móvil y desarrollo web?",
      answer:
        "El desarrollo móvil se enfoca en crear aplicaciones diseñadas específicamente para dispositivos móviles, como teléfonos y tabletas. Estas aplicaciones suelen instalarse en el dispositivo del usuario y pueden aprovechar características nativas, como la cámara o el GPS. Por otro lado, el desarrollo web se refiere a la creación de sitios web accesibles a través de navegadores web en cualquier dispositivo. Las aplicaciones móviles ofrecen una experiencia más personalizada y pueden funcionar sin conexión, mientras que los sitios web son accesibles desde cualquier navegador y no requieren instalación."
    },
    {
      question: "¿Cómo elegir entre una aplicación móvil y un sitio web para mi proyecto?",
      answer:
        "La elección depende de tus objetivos y necesidades específicas. Si buscas llegar a un público amplio a través de diferentes dispositivos y sistemas operativos, un sitio web puede ser la mejor opción. Si necesitas funciones específicas que aprovechen características de dispositivos móviles, como notificaciones push o acceso a hardware, una aplicación móvil podría ser la elección adecuada. En muchos casos, una estrategia combinada que incluya un sitio web responsive y una aplicación móvil puede ser ideal para abordar diversas necesidades."
    },
    {
      question: "¿Cuáles son las tecnologías y plataformas que su equipo domina?",
      answer:
        "Nuestro equipo tiene experiencia en una amplia gama de tecnologías y plataformas, incluyendo, pero no limitado a: HTML, CSS, JavaScript, React, Angular, Node.js, Ruby on Rails, Python, Swift, Kotlin, y más. Trabajamos en plataformas como iOS, Android, y diversas tecnologías web. Nuestra experiencia nos permite adaptarnos a tus necesidades específicas y seleccionar las mejores herramientas para tu proyecto."
    },
    {
      question: "¿Cuál es el proceso de desarrollo típico que siguen para un proyecto nuevo?",
      answer:
        "Nuestro proceso de desarrollo comienza con una fase de planificación detallada, donde colaboramos estrechamente contigo para comprender tus objetivos y requisitos. Luego, diseñamos una estrategia y un plan de proyecto sólidos. Desarrollamos el software utilizando las mejores prácticas de codificación y metodologías ágiles. Realizamos pruebas rigurosas para garantizar la calidad y la seguridad, y finalmente, entregamos el producto y ofrecemos soporte continuo. Nuestro enfoque está en la transparencia, la comunicación constante y la entrega de soluciones de alta calidad que satisfagan tus necesidades."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="FAQ">
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading > {heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
