import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import DesignIllustration from "../../images/design-illustration.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-75`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = styled.div`
  ${tw`pt-40 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`}

  @media (max-width: 767px) {
    ${tw`pt-24`} /* Adjust the padding-top for smaller screens */
  }
`;

const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;


const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

// make a function onclick in primary action to scroll to the contact section

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;


const makeSmoothScroll = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}

export default (  {
  imageSrc=DesignIllustration,
  imageCss=null,
  imageDecoratorBlob = false,}) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink  href="#quienes-somos" onClick= {() => makeSmoothScroll("quienes-somos")}>
        Quienes somos 
      </NavLink>
      <NavLink href="#servicios" onClick= {() => makeSmoothScroll("servicios")}>
        Servicios
      </NavLink>
      <NavLink href="#nuestro-equipo" nClick= {() => makeSmoothScroll("nuestro-equipo")}>
        Nuestro equipo
      </NavLink>
      <NavLink href="#FAQ" onClick = {() => makeSmoothScroll("FAQ")}>
        FAQ
      </NavLink>
      <NavLink href="#contacto" onClick = {() => makeSmoothScroll("contacto")}>
        Contacto
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink onClick = {() => makeSmoothScroll("quienes-somos")}>
        Conocenos
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Your next step to 4.0.</Notification>
            <Heading>
                <h1>Quantum</h1>
              <SlantedBackground>
              <h2>
              Líderes en desarrollo
              </h2>
                </SlantedBackground>
            </Heading>
            <PrimaryAction onClick = {() => makeSmoothScroll("contacto")}>Contactanos</PrimaryAction>
          </LeftColumn>

          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
          
          {/* <RightColumn>

            
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
          </RightColumn> */}
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
